import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { BehaviorSubject, from, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class StaticContentService {
  private content = new BehaviorSubject<string | null>(null);
  public content$: Observable<string | null> = this.content.asObservable();
  constructor(@Inject(PLATFORM_ID) private platformId: object) {}

  async read(url: string): Promise<boolean> {
    try {
      const content = await readContentHtmlFile(url);
      this.content.next(content);
      return true;
    } catch (e) {
      return false;
    }
  }

  getStaticContent(url: string): Observable<boolean> {
    return from(this.read(url));
  }
}

export function readContentHtmlFile(url: string): Promise<string> {
  return new Promise(async (resolve, reject) => {
    const response = await fetch(url + '.html');
    if (response.status === 200) {
      const htmlString = await response.text();
      resolve(htmlString);
    }
    reject(new Error('Failed to load content. Status code: ' + response.status));
  });
}
